<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "AppView",
  created() {
    if (localStorage.getItem("token")){
      this.$store.dispatch("auth/me");
    } else {
      this.$store.dispatch("auth/logout");
    }
  },

};
</script>
