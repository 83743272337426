import Vue from "vue";

//FILTER
import moment from "moment";
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("DD MMM YYYY");
  }
});

Vue.filter("sortDate", function (value) {
  if (value) {
    return moment(value).format("DD MMM");
  }
});

Vue.filter("setTempo", function (date, jenis) {
  if (date) {
    let custom_date = "";
    if (jenis == "tempo") {
      custom_date = moment(date).add(7, "days").format("DD MMM YYYY");
    } else if (jenis == "tempo_14") {
      custom_date = moment(date).add(14, "days").format("DD MMM YYYY");
    } else if (jenis == "tempo_21") {
      custom_date = moment(date).add(21, "days").format("DD MMM YYYY");
    }
    return custom_date;
  }
});

Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(value).format("DD MMM HH:mm");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value).format("DD MMM YYYY HH:mm");
  }
});

Vue.filter("formatDayTime", function (value) {
  if (value) {
    moment.locale("id");
    return moment(value).format("dddd, DD MMM YYYY HH:mm");
  }
});

Vue.filter("currency", function (value, prefix = null) {
  // if(value < 0){
  //     value = value * -1;
  // }
  if (value && !isNaN(value)) {
    let val = (value / 1).toFixed(0).replace(".", ",");

    let currency = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (prefix != null) {
      return prefix + " " + currency;
    } else {
      return currency;
    }
  }
  return value;
});

Vue.filter("gram", function (value) {
  if (value) {
    let val = (value / 1).toFixed(0).replace(".", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("uppercase", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("truncate", function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter("separate", function (text) {
  const str = text;
  const result = str.replace(/_/g, " ");
  return result;
});
