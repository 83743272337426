<template>
  <nav class="mt-2">
    <ul
      class="nav nav-pills nav-sidebar flex-column"
      data-widget="treeview"
      role="menu"
      data-accordion="false"
    >
      <li
        @click="checkCredential"
        v-for="(item, i) in menu"
        :key="i"
        :class="
          item.children
            ? 'has-treeview nav-item'
            : item.is_header
            ? 'nav-header pt-2 pb-0'
            : 'nav-item'
        "
      >
        <b v-if="item.is_header">{{ item.text }}</b>
        <router-link
          :to="item.to"
          v-if="!item.children && !item.is_header"
          @click="loadMenu"
          class="nav-link clickable"
        >
          <i class="nav-icon fa" :class="item.icon"></i>
          <p>
            {{ item.text }}
            <i class="fas fa-angle-left right" v-if="item.children"></i>
            <span class="badge badge-purple right" v-if="item.badge">{{
              item.badge
            }}</span>
          </p>
        </router-link>
        <a href="#" class="nav-link" v-if="item.children">
          <i class="nav-icon fa" :class="item.icon"></i>
          <p>
            {{ item.text }}
            <i class="fas fa-angle-left right" v-if="item.children"></i>
          </p>
        </a>
        <ul class="nav nav-treeview" v-if="item.children && !item.is_header">
          <li class="nav-item" v-for="(child, c) in item.children" :key="c">
            <router-link
              :to="child.to"
              class="nav-link clickable pl-5"
              @click="loadMenu"
            >
              <p>
                {{ child.text }}
              </p>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "vue-sidebar",
  data() {
    return {
      menu: [
        {
          text: "Dashboard",
          icon: "fa-home",
          to: "/dashboard",
          children: null,
          is_header: false,
        },
        {
          text: "Proses Pengiriman",
          icon: "fa-truck",
          to: "/process-transfer",
          children: null,
          is_header: false,
        },
        {
          text: "Pengajuan Pengiriman",
          icon: "fa-file",
          to: "/history-transport",
          children: null,
          is_header: false,
        },
        // {
        //   text: "Invoice",
        //   icon: "fa-cash-register",
        //   to: "#",
        //   children: [
        //     { text: "Invoice Pending", to: "/invoice-pending" },
        //     { text: "Invoice Selesai", to: "/invoice-selesai" },
        //   ],
        // },
        // {
        //   text: "Stock Kendaraan",
        //   icon: "fa-box",
        //   to: "#",
        //   children: [
        //     { text: "Stock Kendaraan", to: "/admin/sale-product-list" },
        //   ],
        // },
      ],
    };
  },
  methods: {
    loadMenu() {
      // axios.get(this.base_api + "menu").then(({ data }) => {
      //   data.status
      //     ? (this.menu = data.result)
      //     : (window.location.href = "http://" + window.location.host);
      // });
    },
    checkCredential() {
      this.loadMenu();
    },
  },
  created() {
    this.loadMenu();

    // Fire.$on("LoadSidebar", () => {
    //   this.loadMenu();
    // });

    setInterval(() => {
      this.loadMenu();
    }, 600 * 1000);
  },
  mounted() {
    window.$('[data-widget="treeview"]').Treeview("init");
  },
};
</script>
<style scoped>
.nav-icon.fa {
  color: #dab96b !important;
}
</style>
