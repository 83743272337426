import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "FirstPage",
    component: () => import("../views/Page.vue"),
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        auth: true,
      },
      {
        path: "/process-invoice/:no_invoice",
        name: "process-invoice",
        component: () => import("../views/Invoice/ProcessInvoice.vue"),
      },
      {
        path: "/process-transfer-final",
        name: "process-transfer-final",
        component: () =>
          import("../views/ProcessTransfer/ProsesTransferFinal.vue"),
      },
      {
        path: "/process-transfer",
        name: "process-transfer",
        component: () =>
          import("../views/ProcessTransfer/ProsesPengiriman.vue"),
      },
      {
        path: "/history-transport",
        name: "history-transport",
        component: () => import("../views/History/History.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // Check if the route requires authentication
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // Check if the user is authenticated
//     if (!localStorage.getItem("token")) {
//       // Redirect to login if not authenticated
//       next({
//         name: "Login",
//       });
//     } else {
//       // Continue to the route if authenticated
//       next();
//     }
//   } else {
//     // Continue to the route if it doesn't require authentication
//     next();
//   }
// });

export default router;
