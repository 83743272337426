import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "VueMixin",
  data() {
    return {
      stock_config: {
        min: 0,
        separator: ".",
        nullValue: 0,
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    popToast({
      title = "Berhasil",
      icon = "success",
      position = "top-end",
      btn = false,
      timer = 2500,
    }) {
      Swal.fire({
        position: position,
        icon: icon,
        title: title,
        toast: true,
        showConfirmButton: btn,
        timer: icon === "warning" ? 3000 : timer,
      });
    },
  },
};
