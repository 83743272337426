<template>
  <span>
    <span v-if="data.status === 1" class="badge badge-success">{{
      data.status_label ? data.status_label : "aktif"
    }}</span>
    <span v-if="data.status === 0" class="badge badge-secondary">{{
      data.status_label ? data.status_label : "nonaktif"
    }}</span>
    <span v-if="data.status === 200" class="badge badge-success">{{
      data.status_label ? data.status_label : "aktif"
    }}</span>
    <span v-if="data.status === 100" class="badge badge-secondary">{{
      data.status_label ? data.status_label : "nonaktif"
    }}</span>
  </span>
</template>

<script>
export default {
  props: ["data"],
};
</script>
