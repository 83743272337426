import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// require("./bootstrap");
window.$ = window.jQuery = require("jquery");

require("bootstrap");
require("admin-lte");

require("./vue-filter");
import "./plugin/bootstrap/js/bootstrap.bundle.min.js";

import globalMixin from "./vue-mixin.js";

Vue.component("vue-pagination", require("laravel-vue-pagination"));
Vue.component("vue-topbar", require("./components/TopBar").default);
Vue.component("vue-sidebar", require("./components/Sidebar").default);
Vue.component("vue-spinner", require("./components/Spinner").default);
Vue.component("vue-status", require("./components/StatusLabel").default);

import axios from "axios";

const axiosData = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosData.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//---- VForm ----
import { Form, HasError, AlertError } from "vform";

// Define a custom axios instance for vform
window.Form = Form;
Form.axios = axiosData;
Vue.prototype.$axios = axiosData;

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

// install vuerx & clipper
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper";
Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
  },
});

//---- PROGRESS BAR ----
import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "rgb(255,28,25)",
  failedColor: "red",
  thickness: "4px",
});

//---- SWEET ALERT ----
import Swal from "sweetalert2";
Vue.prototype.$swal = Swal;

//---- Fire Global Event ----
window.Fire = new Vue();

//MAP
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyDuLAIKVzdMdIfMVSxZkU5ZQMJYmPHDiZU',
    libraries: "places",
  },
});

//DATE TIME PICKER
import datePicker from "vue-bootstrap-datetimepicker";
Vue.use(datePicker);

//TIME PICKER
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
// Vue.component('main-tobar', require('./components/TopBar').default);

//Loader
// import Loading from "vue-loading-overlay";
// Vue.component("vue-loading-overlay", Loading);

// ---- SELECT OPTIONS ----
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import Multiselect from "vue-multiselect";
Vue.component("vue-multiselect", Multiselect);

import feather from "vue-icon";
Vue.use(feather, "v-icon");

import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";
Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

import Money from "v-money";
Vue.use(Money);

Vue.config.productionTip = false;
Vue.mixin(globalMixin);

import VueNumberFormat from "@coders-tm/vue-number-format";
Vue.use(VueNumberFormat);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
  ],
};

Vue.use(options);

new Vue({
  router,
  store,
  created() {
    this.$store.dispatch("auth/init");
  },
  render: (h) => h(App),
}).$mount("#app");
