<template>
  <span
    v-if="loading"
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
</template>

<script>
export default {
  name: "vue-spinner",
  props: ["loading"],
};
</script>
