import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "vue-router";

Vue.use(Vuex);

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    vehicle: null,
    vehicle_set: true,
  },
  getters: {
    getVehicle: (state) => {
      return state.vehicle;
    },
    vehicle_set: (state) => {
      return state.vehicle_set;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
      if (user) {
        if (user.vehicle) {
          console.log(true);
          state.vehicle = user.vehicle;

          state.vehicle_set = true;
        } else {
          state.vehicle = null;
          state.vehicle_set = false;
        }
      } else {
        state.vehicle = null;
        state.vehicle_set = false;
      }
    },
  },
  actions: {
    init({ commit }) {
      // get token from Local Storage
      const token = localStorage.getItem("token");
      if (token) {
        // commit token to state
        commit("setToken", token);
      }
    },
    login({ commit }, { token, user }) {
      localStorage.setItem("token", token);
      api.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      commit("setToken", token);
      commit("setUser", user);
    },
    logout({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      localStorage.removeItem("token");
    },
    me({ commit }) {
      if (localStorage.getItem("token")) {
        api
          .get("auth/me")
          .then((data) => {
            commit("setUser", data.data);
          })
          .catch(() => {
            localStorage.setItem("token", "");
            router.push("/login");
          });
      }
    },
  },
};
