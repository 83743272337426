<template>
  <nav
    class="main-header navbar navbar-expand navbar-light border-bottom d-flex justify-content-between"
  >
    <div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link collapse-button" data-widget="pushmenu" href="#"
            ><i class="fa fa-bars"></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="d-flex align-items-center">
      <span class="mr-3 d-none d-sm-inline">{{ today | formatDateTime }}</span>
      <button
        class="btn mx-1 d-none d-sm-inline"
        style="width: 180px; background-color: #efefef; color: #c01b1b"
        @click="logout"
      >
        Log Out
      </button>
      <button
        class="btn mx-1 d-inline d-sm-none"
        style="background-color: #efefef; color: #c01b1b"
        @click="logout"
      >
        <i class="fa fa-sign-out-alt"></i>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      today: "",
      sec: "",
    };
  },
  methods: {
    today_date() {
      let today = new Date();
      this.today = today;
      let date_raw = new Date(today);
      this.sec = date_raw.getSeconds();
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  created() {
    this.today_date();

    setInterval(() => {
      this.today_date();
    }, 60000);
  },
};
</script>

<style scoped>
.show-md {
  display: none;
}
@media (min-width: 575px) {
  .show-md {
    display: list-item;
  }
}
</style>
